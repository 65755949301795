import axios from "axios";
import { jwtDecode } from "jwt-decode";
// import dotenv from "dotenv";

// dotenv.config();

interface DecodedToken {
  exp: number;
  [key: string]: any;
}

const API_URL = "";

export const login = async (
  username: string,
  password: string
): Promise<string> => {
  const response = await axios.post(`${API_URL}/auth/login`, {
    username,
    password,
  });
  console.log("response", response.data);
  if (!response.data.accessToken) {
    throw new Error("No token found in response");
  }

  localStorage.setItem("username", username);

  return response.data.accessToken;
};

// export const isTokenValid = (token: string): boolean => {
//   // Implement token validation logic here
//   // For example, check if the token is not expired
//   return true;
// };

export const isTokenExpired = (token: string): boolean => {
  if (!token) return true;

  try {
    const decoded = jwtDecode<DecodedToken>(token);
    const currentTime = Math.floor(Date.now() / 1000);
    return decoded.exp < currentTime;
  } catch (error) {
    return true;
  }
};

export const getToken = (): string | null => {
  const token = localStorage.getItem("token");
  if (token && !isTokenExpired(token)) {
    return token;
  }
  localStorage.removeItem("token");
  return null;
};

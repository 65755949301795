import React, { useState, useEffect } from "react";
import "./App.css";
import Sidebar from "./components/sidebar";
import UserSearch from "./components/user-search";
import Login from "./components/login";
import { getToken } from "./services/auth-service";

const App: React.FC = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const checkAuth = () => {
      const token = getToken();
      setIsAuthenticated(!!token);
    };

    checkAuth();
    // Set up an interval to periodically check token validity
    const intervalId = setInterval(checkAuth, 60000); // Check every minute

    return () => clearInterval(intervalId);
  }, []);

  const handleLoginSuccess = (token: string) => {
    localStorage.setItem("token", token);
    setIsAuthenticated(true);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    setIsAuthenticated(false);
  };

  if (!isAuthenticated) {
    return <Login onLoginSuccess={handleLoginSuccess} />;
  }

  return (
    <div className="App">
      <Sidebar onLogout={handleLogout} />
      <main className="py-10 lg:pl-72">
        <div className="px-4 sm:px-6 lg:px-8">
          <UserSearch />
        </div>
      </main>
    </div>
  );
};

export default App;

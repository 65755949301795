import React, { useState } from "react";
import api from "../services/api";

interface User {
  id: string;
  email: string;
  kycLevel: boolean;
  reasonForKycFailure?: string;
  smartAccounts: { address: string; chainId: number }[];
  addresses: { address: string; type: number }[];
  taxId?: string;
  kycType?: string;
}

const UserSearch: React.FC = () => {
  const [searchType, setSearchType] = useState<string>("email");
  const [searchValue, setSearchValue] = useState<string>("");
  const [user, setUser] = useState<User | null>(null);
  const [error, setError] = useState<string | null>(null);

  const handleSearch = async (e?: React.FormEvent) => {
    if (e) e.preventDefault();

    try {
      setError(null);
      let userResponse;
      switch (searchType) {
        case "email":
          userResponse = await api.get(`/api/user/email/${searchValue}`);
          break;
        case "taxId":
          userResponse = await api.get(`/api/user/taxId/${searchValue}`);
          break;
        case "smartAccount":
          userResponse = await api.get(`/api/user/smartAccount/${searchValue}`);
          break;
        case "address":
          userResponse = await api.get(`/api/user/address/${searchValue}`);
          break;
        default:
          throw new Error("Invalid search type");
      }
      const userData: User = userResponse?.data;
      setUser(userData);
    } catch (err) {
      setError(`User not found or server error: ${err}`);
      setUser(null);
    }
  };

  const searchTypes = [
    { value: "email", title: "Email" },
    { value: "taxId", title: "Tax ID" },
    { value: "smartAccount", title: "Smart Account" },
    { value: "address", title: "Address" },
  ];

  console.log({ searchType, searchValue, user, error });

  return (
    <div>
      <div className="px-4 sm:px-0">
        <h3 className="text-xl font-semibold leading-7 text-gray-900">
          User Search
        </h3>
        <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
          Search for user information.
        </p>
      </div>
      <div className="mt-6 border-t border-gray-100">
        <dl className="divide-y divide-gray-100">
          <div className="py-6">
            <fieldset>
              <legend className="text-sm font-semibold leading-6 text-gray-900">
                Search Type
              </legend>
              <p className="mt-1 text-sm leading-6 text-gray-600">
                Which user information you have to look for...
              </p>
              <div className="mt-6 space-y-6 sm:flex sm:items-center sm:space-x-10 sm:space-y-0">
                {searchTypes.map((type) => (
                  <div key={type.value} className="flex items-center">
                    <input
                      id={type.value}
                      name="search-type"
                      type="radio"
                      value={type.value}
                      checked={searchType === type.value}
                      onChange={(e) => setSearchType(e.target.value)}
                      className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                    />
                    <label
                      htmlFor={type.value}
                      className="ml-3 block text-sm font-medium leading-6 text-gray-900"
                    >
                      {type.title}
                    </label>
                  </div>
                ))}
              </div>
            </fieldset>
          </div>
          <div className="w-1/2 pt-6">
            <label
              htmlFor="email"
              className="text-sm font-medium leading-6 text-gray-900"
            >
              Search value
            </label>
            <div className="mt-2">
              <input
                type="text"
                className="block w-full p-2 sm:text-xl rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearch();
                  }
                }}
              />
            </div>
          </div>

          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900"></dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              <button
                className="px-4 py-2 bg-indigo-600 text-white rounded-md"
                onClick={handleSearch}
              >
                Search
              </button>
            </dd>
          </div>
        </dl>
        {error && <p className="mt-4 text-red-600">{error}</p>}
        {user && (
          <div className="mt-6 border-t border-gray-100">
            <dl className="divide-y divide-gray-100">
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  User ID
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {user.id}
                </dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  Email address
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {user.email}
                </dd>
              </div>
              {user.taxId && (
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">
                    Tax ID
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {user.taxId} ({user.kycType})
                  </dd>
                </div>
              )}
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  KYC Level
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                  {user.kycLevel}
                </dd>
              </div>
              {user.reasonForKycFailure && (
                <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                  <dt className="text-sm font-medium leading-6 text-gray-900">
                    KYC Failure Reason
                  </dt>
                  <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                    {user.reasonForKycFailure}
                  </dd>
                </div>
              )}
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  Smart Accounts
                </dt>
                <dd className="mt-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  <ul className="divide-y divide-gray-100 rounded-md border border-gray-200">
                    {user.smartAccounts.map((account, index) => (
                      <li
                        key={index}
                        className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6"
                      >
                        <div className="flex w-0 flex-1 items-center">
                          <div className="ml-4 flex min-w-0 flex-1 gap-2">
                            <span className="truncate font-medium">
                              {account.address}
                            </span>
                            <span className="flex-shrink-0 text-gray-400">
                              Chain ID: {account.chainId}
                            </span>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </dd>
              </div>
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  Addresses
                </dt>
                <dd className="mt-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  <ul className="divide-y divide-gray-100 rounded-md border border-gray-200">
                    {user.addresses.map((address, index) => (
                      <li
                        key={index}
                        className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6"
                      >
                        <div className="flex w-0 flex-1 items-center">
                          <div className="ml-4 flex min-w-0 flex-1 gap-2">
                            <span className="truncate font-medium">
                              {address.address}
                            </span>
                            <span className="flex-shrink-0 text-gray-400">
                              Type: {address.type}
                            </span>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </dd>
              </div>
            </dl>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserSearch;
